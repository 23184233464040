import { React, useState, useImperativeHandle, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OrderStatus, routesConfig } from '../../util/util';
import { saveFirstStep, setRtsVisible, updateOrder } from '../../store/action/OrderAction';
import { useCallCenterFlag, useCartData, useServiceablitityData, useSessionData, useVasData, useVendor } from '../../hooks/order';
import { getValid$Cost } from '../../../src/common/common';
import useWindowDimensions from '../common/useWindowDimensions';
import CartInformation from '../common/CartInformation';
import RtsModal from '../common/RtsModal';


const FixedCardFooter = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  const { orderInfo } = useSessionData();
  const { isInternetAdded, isMobileAdded, isProductsAdded, vasPlan, monthlyCharge = 0, getOneTimeChargesLabelValue } = useCartData();
  let { servRef } = useCartData();
  const { isVasOnlyFlow } = useVasData();
  const { id } = orderInfo;
  const [headerClass, setHeaderClass] = useState('')
  const { showCallNow } = useCallCenterFlag();
  const { scheduleAppt, } = useServiceablitityData();
  const {vendor} = useVendor();

  const handleCheckout = () => {
    handleClick();
    dispatch(updateOrder({ orderStatus: OrderStatus.addedToCart, serviceReference: servRef }, id));
    dispatch(saveFirstStep(!isProductsAdded));
  }

  const onCheckoutClick = () => {
    if (vendor?.toLowerCase() === 'verizon' && !scheduleAppt?.length) {
      dispatch(setRtsVisible(true));
    } else {
      handleCheckout();
      navigate(routesConfig.checkout);
    }
  }

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    if (isActive) {
      setHeaderClass('menu-active');
    } else {
      setHeaderClass('');
    }
  };

  const handleHaderClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);

  };

  const handleAddToCart = () => {
    dispatch(updateOrder({ orderStatus: OrderStatus.vasAdded, serviceReference: servRef }, id));
    navigate(routesConfig.checkout);
  }
  const isVaspage = window.location.href.includes(routesConfig.vasPage);

  useImperativeHandle(ref, () => ({
    cartClick() {
      handleHaderClick();
    },
  }));

  return (
    <>
      {isMobile && !showCallNow &&
        <div className={`on-footer-m-fix ${isActive ? 'footer-cart-unfixed' : ''} ${isProductsAdded ? 'on-simple-load' : ''}${headerClass}`}>
          {/* <div className="on-footer-m-fix"> */}
          <div className={`inner-fixed-ftr ${isProductsAdded && !isVaspage ? 'continue-btn-removed' : ''}`}>
            <div className="footer-inner-top-fix">
              <div className="due-today">
                <p>Due Today <b>{getValid$Cost(isVasOnlyFlow ? monthlyCharge : getOneTimeChargesLabelValue())}</b></p>
              </div>
              <div onClick={() => {
                handleClick()
                document.body.classList.remove('cart-clicked');
              }}
              className={`text-center ${isProductsAdded && !isVaspage ? 'text-right' : ''}`}><i className="fa-solid fa-angle-up" ></i></div>
              {!isProductsAdded &&
                <div className="text-right">
                  {!isProductsAdded && (
                    <button className="blue-button outer-continue-btn" disabled={!isMobileAdded && !isInternetAdded} onClick={() => {
                      onCheckoutClick()
                      setIsActive(false)
                      document.body.classList.remove('body-overflow-hide');
                    }}>Continue <i className="fa-solid fa-arrow-right"></i></button>
                  )}
                </div>
              }
              {isProductsAdded && isVaspage && (
                <button className="blue-button outer-continue-btn" disabled={vasPlan.length === 0} onClick={() => {
                  handleAddToCart()
                  setIsActive(false)
                  document.body.classList.remove('body-overflow-hide');
                }}>Continue <i className="fa-solid fa-arrow-right"></i></button>
              )}

            </div>
          </div>
          <div className="footer-cart-show-fiexd">
            <div>
              <CartInformation handleContClick={handleClick} />
            </div>
            {/* <div className="close-btn" onClick={() => setIsActive(false)}>
              <div className="text-center">Close <i className="fa-solid fa-xmark"></i></div>
            </div> */}
            <div className="close-btn" onClick={() => {
              setIsActive(false)
              document.body.classList.remove('body-overflow-hide');
              setHeaderClass('');
            }}>
              <div className="text-center">Close <i className="fa-solid fa-xmark"></i></div>
            </div>
          </div>

        </div>
      }
      <RtsModal handleCheckout={handleCheckout} />
    </>
  )
});

export default FixedCardFooter;
